import React from 'react'
import { Outlet } from 'react-router-dom'
import Index from '../../../Index';
import PagesIndex from '../../../PagesIndex';

const Layout = () => {
  return (
    <>
      <PagesIndex.Header />
      <Outlet />
      <PagesIndex.Footer />
      <Index.Box className="top-scroll-box" id="top-scroll-box">
        <Index.Button className="top-scroll-btn" onClick={() => window.scrollTo({ top: 0, behavior: 'smooth' })}><img src={PagesIndex.Svg.topArrow} className="top-scroll-icon" /></Index.Button>
      </Index.Box>
    </>
  )
}

export default Layout;