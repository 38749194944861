// import mobilemenu from './images/svg/mobile-menu.svg';













const Png = {
      // mobilemenu,

}

export default Png;