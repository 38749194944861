import React from 'react'
import Index from '../../../../container/Index'

export default function AboutUs() {
  return (
      <Index.Box className="about-us-sec">
        <Index.Box className="container">
          <Index.Box className="about-us-wrapper">
          <Index.motion.div
                animate={{ y: 250, opacity: 0, }}
                transition={{ duration: 0.8, delay: 0.1 }}
                whileInView={{ y: 0, opacity: 1, }}>
            <Index.Typography className='about-us-title'>
            <span className='about-us-span'>Aurum</span> Is Your Ticket to a New Era of Trading.
            </Index.Typography>

            <Index.Typography className="about-us-para">
            Built on the lightning-fast Solana blockchain, Aurum, as precious as gold, offers unparalleled speed, security, and efficiency. We've stripped away the complexities, making trading accessible to everyone, from seasoned pros to crypto newcomers. Join the Aurum revolution and experience the future of finance.
            </Index.Typography>
            </Index.motion.div>
          </Index.Box>
        </Index.Box>
      </Index.Box>
  )
}
