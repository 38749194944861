import React, { useState } from 'react'
import Index from '../../../../container/Index'
import ReactApexChart from 'react-apexcharts'

export default function Tokenomics() {
  const [pai, setPie] = useState({
    series: [44, 55, 41, 17, 15 , 25],
    options: {
      chart: {
        type: 'donut',
        expanndOnClick: false
      },
      fill: {
        colors: ['#FFA523', '#00B4B0', '#9F49A3', '#FF3169', '#c85803', '#0068b9']
      },
      stroke: {
        show: false
      },
      dataLabels: {
        enabled: false,
      },
      tooltip: {
        enabled: false,
      },
      marker: {
        show: false,
      },
      legend: {
        show: false
      },
      // responsive: [{
      //   breakpoint: 480,
      //   options: {
      //     chart: {
      //       width: 200
      //     },
      //   }
      // }]
    },
  })
  return (
    <>
      <Index.Box className="tokenomics-sec-bg">
        <Index.Box className="tokenomics-wrapper">
            <Index.Typography className='tokenomics-heading'>Discover the Dynamics of
            </Index.Typography>
            <Index.Typography className='tokenomics-title'>Aurum Tokenomics</Index.Typography>
        </Index.Box>
      </Index.Box>

      <Index.Box className="tokenomics-sec">
        <Index.Box className="container">
          <Index.Box className="tokenomics-row" display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={{ xs: 2, sm: 2, md: 2, lg: 2 }}>
            <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column order-2">
              <Index.Box className="tokenomics-content-main">
                <Index.Box className="tokenomics-content-bg">
                  <Index.Box className="tokenomics-content-box">
                    <Index.Box className="tokenomics-content-left">
                      <Index.Box className="tokenomics-content-inner-flex">
                        <Index.Box className="tokenomics-content-color-box yellow-color"></Index.Box>
                        <Index.Box className="token-content-wraper">
                          <Index.Typography className="tokenomics-content-title">40% Public Pool</Index.Typography>
                          {/* <Index.Typography className="tokenomics-content-para">Lorem ipsum dolor sit amet, consectetur</Index.Typography> */}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="tokenomics-content-right">
                      <Index.Typography className="tokenomics-content-lable">Amount</Index.Typography>
                      <Index.Typography className="tokenomics-content-digit">40,000,000,000</Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="tokenomics-content-box">
                    <Index.Box className="tokenomics-content-left">
                      <Index.Box className="tokenomics-content-inner-flex">
                        <Index.Box className="tokenomics-content-color-box cyne-color"></Index.Box>
                        <Index.Box className="token-content-wraper">
                          <Index.Typography className="tokenomics-content-title">20% Reserve Pool</Index.Typography>
                          {/* <Index.Typography className="tokenomics-content-para">Lorem ipsum dolor sit amet, consectetur</Index.Typography> */}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="tokenomics-content-right">
                      <Index.Typography className="tokenomics-content-lable">Amount</Index.Typography>
                      <Index.Typography className="tokenomics-content-digit">20,000,000,000</Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="tokenomics-content-box">
                    <Index.Box className="tokenomics-content-left">
                      <Index.Box className="tokenomics-content-inner-flex">
                        <Index.Box className="tokenomics-content-color-box violet-color"></Index.Box>
                        <Index.Box className="token-content-wraper">
                          <Index.Typography className="tokenomics-content-title">20% Burn Pool</Index.Typography>
                          {/* <Index.Typography className="tokenomics-content-para">Lorem ipsum dolor sit amet, consectetur</Index.Typography> */}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="tokenomics-content-right">
                      <Index.Typography className="tokenomics-content-lable">Amount</Index.Typography>
                      <Index.Typography className="tokenomics-content-digit">20,000,000,000</Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="tokenomics-content-box">
                    <Index.Box className="tokenomics-content-left">
                      <Index.Box className="tokenomics-content-inner-flex">
                        <Index.Box className="tokenomics-content-color-box pink-color"></Index.Box>
                        <Index.Box className="token-content-wraper">
                          <Index.Typography className="tokenomics-content-title">5% Team Pool</Index.Typography>
                          {/* <Index.Typography className="tokenomics-content-para">Lorem ipsum dolor sit amet, consectetur</Index.Typography> */}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="tokenomics-content-right">
                      <Index.Typography className="tokenomics-content-lable">Amount</Index.Typography>
                      <Index.Typography className="tokenomics-content-digit">5,000,000,000</Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="tokenomics-content-box">
                    <Index.Box className="tokenomics-content-left">
                      <Index.Box className="tokenomics-content-inner-flex">
                        <Index.Box className="tokenomics-content-color-box orange-color"></Index.Box>
                        <Index.Box className="token-content-wraper">
                          <Index.Typography className="tokenomics-content-title">5% Rewards Pool</Index.Typography>
                          {/* <Index.Typography className="tokenomics-content-para">Lorem ipsum dolor sit amet, consectetur</Index.Typography> */}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="tokenomics-content-right">
                      <Index.Typography className="tokenomics-content-lable">Amount</Index.Typography>
                      <Index.Typography className="tokenomics-content-digit">5,000,000,000</Index.Typography>
                    </Index.Box>
                  </Index.Box>
                  <Index.Box className="tokenomics-content-box">
                    <Index.Box className="tokenomics-content-left">
                      <Index.Box className="tokenomics-content-inner-flex">
                        <Index.Box className="tokenomics-content-color-box dark-blue-color"></Index.Box>
                        <Index.Box className="token-content-wraper">
                          <Index.Typography className="tokenomics-content-title">10% Development/Marketing Pool</Index.Typography>
                          {/* <Index.Typography className="tokenomics-content-para">Lorem ipsum dolor sit amet, consectetur</Index.Typography> */}
                        </Index.Box>
                      </Index.Box>
                    </Index.Box>
                    <Index.Box className="tokenomics-content-right">
                      <Index.Typography className="tokenomics-content-lable">Amount</Index.Typography>
                      <Index.Typography className="tokenomics-content-digit">10,000,000,000</Index.Typography>
                    </Index.Box>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
            <Index.Box gridColumn={{ xs: "span 12", sm: "span 6", md: "span 6", lg: "span 6" }} className="grid-column order-1">
              <Index.Box className="tokenomics-content-main">
                <Index.Box className="token-chart-main">
                  <ReactApexChart options={pai.options} series={pai.series} type="donut" />
                  <Index.Box className="token-chart-value-box">
                    <Index.Typography className="token-chart-value-lable">Total Supply</Index.Typography>
                    <Index.Typography className="token-chart-value-text">100,000,000,000</Index.Typography>
                  </Index.Box>
                </Index.Box>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  )
}
