import React from 'react'
import Index from '../../../Index'
import PagesIndex from '../../../PagesIndex'


export default function Home() {
  return (
    <>
      <Index.Box className="home-hero-main"  id="home-hero-main">
        <PagesIndex.HeroSection />
      </Index.Box>
      <Index.Box className="about-us-main" id="about-us-main">
        <PagesIndex.AboutUs />
      </Index.Box>
      <Index.Box className="tokenomics-main" id="tokenomics-main">
        <PagesIndex.Tokenomics />
      </Index.Box>
      <Index.Box className="roadmap-main" id="roadmap-main">
        <PagesIndex.Roadmap />
      </Index.Box>
      {/* <Index.Box className="team-main" id="team-main">
        <PagesIndex.Team />
      </Index.Box> */}
      <Index.Box className="faq-main" id="faq-main">
        <PagesIndex.Faq />
      </Index.Box>
    </>
  )
}
