import React, { useEffect, useState } from 'react'
import Index from '../../../container/Index';
import PagesIndex from '../../../container/PagesIndex';

export default function Header() {


  // const downloadFile = (e) => {
  //   e.preventDefault();
  //   fetch("../../../assets/images/pdf/aurum-whitepaper.pdf").then((response) => {
  //     response.blob().then((blob) => {
  //       const fileURL = window.URL.createObjectURL(blob);
  //       let alink = document.createElement("a");
  //       alink.href = fileURL;
  //       alink.download = "aurum-whitepaper.pdf";
  //       alink.click();
  //     });
  //   });
  // };

  const [isActive, setIsActive] = useState(false);

  const handleClick = event => {
    // 👇️ toggle isActive state on click
    setIsActive(current => !current);
    document.body.classList[isActive ? "remove" : "add"]('body-overflow');
  };
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY === 0) {
        document.getElementById("header-main").classList.remove("sticky-header");
        document.getElementById("top-scroll-box").classList.remove("top-scroll-box-show");
      } else {
        document.getElementById("header-main").classList.add("sticky-header");
        document.getElementById("top-scroll-box").classList.add("top-scroll-box-show");
      }
    });
  }, [])
  const handleClickMenuItem = (id) => {
    var element = document.getElementById(id)
    element.scrollIntoView({ behavior: "smooth", block: "start" });
    handleClick();
  }
  return (
    <>
      <Index.Box className="header-main">
        <Index.Box className="container">
          <Index.Box className="header-row" id="header-main">
            <Index.Box className="header-cust-col">
              <Index.Box className="header-logo-box">
                <img src={PagesIndex.Svg.logo} className='header-logo' alt='logo' />
              </Index.Box>
            </Index.Box>
            <Index.Box className="header-cust-col">
              <Index.Box className={`header-nav-main ${isActive ? "mobilemenu-active" : ""}`}>
                <Index.Box className="mobile-logo-main">
                  <Index.Box className="mobile-logo-flex">
                    <Index.Box className="mobile-logo-box">
                      <img src={PagesIndex.Svg.logo} className='mobile-logo' alt='logo' />
                      <Index.Typography className='mobile-logo-text'>Aurum</Index.Typography>
                    </Index.Box>
                    <Index.Button className="mobile-menu-btn" onClick={handleClick}>
                      <img src={PagesIndex.Svg.closeIcon} className='mobile-menu-close-icon' alt='Close' />
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
                <Index.List className='header-nav-ul'>
                  <Index.ListItem className='header-nav-li'>
                    <Index.Link className='header-nav-link' onClick={() => {
                      handleClickMenuItem("about-us-main")
                    }}>About Us</Index.Link>
                  </Index.ListItem>
                  <Index.ListItem className='header-nav-li'>
                    <Index.Link className='header-nav-link' onClick={() => {
                      handleClickMenuItem("tokenomics-main")
                    }}>Tokenomics</Index.Link>
                  </Index.ListItem>
                  <Index.ListItem className='header-nav-li'>
                    <Index.Link className='header-nav-link' onClick={() => {
                      handleClickMenuItem("roadmap-main")
                    }}>Roadmap</Index.Link>
                  </Index.ListItem>
                  <Index.ListItem className='header-nav-li'>
                    <Index.Link className='header-nav-link' onClick={() => {
                      handleClickMenuItem("team-main")
                    }}>Our Team</Index.Link>
                  </Index.ListItem>
                  <Index.ListItem className='header-nav-li'>
                    <Index.Link className='header-nav-link' onClick={() => {
                      handleClickMenuItem("faq-main")
                    }}>FAQs</Index.Link>
                  </Index.ListItem>
                </Index.List>
              </Index.Box>
            </Index.Box>
            <Index.Box className="header-cust-col">
              <Index.Box className="header-right-main border-btn-main">
                <a className='border-btn header-border-btn' target='_blank' href={PagesIndex.Pdf.whitePaperPdf}>Whitepaper</a>
                <PagesIndex.PrimaryButton btnLabel="Buy Now" className='primary-btn' />
                <Index.Button className='mobile-menu-btn' onClick={handleClick}>
                  <img src={PagesIndex.Svg.mobilemenuIcon} className='mobile-menu-icon' />
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>
    </>
  )
}
